import { Formio, Components } from "@formio/angular";
const Select = Components.components.select;

export default class SelectExtend extends Select {

    get loadedFromUrl() {
        return 'loadedFromUrl';
    }

    getView(value, options) {
        if (this.component.protected) {
            return '--- PROTECTED ---';
        }

        if (this.component.dataSrc === 'url') {
            let result;
            if (this.defaultDownloadedResources.length == 0) {
                if (Formio.cache[this.component.data.url + this.loadedFromUrl + this.component.id]) {
                    result = Formio.cache[this.component.data.url + this.loadedFromUrl + this.component.id].find(x => x.value === value);
                    return result?.label;
                }

                if (!Formio.cache[this.component.data.url + this.component.id]) {
                    Formio.cache[this.component.data.url + this.component.id] = true;
                    this.forceLoad = true;

                    this.updateItems(null, true);

                    let i = 0;
                    let parent = this.parent;
                    while (parent.type !== 'editgrid' && i < 50) {
                        parent = parent.parent;
                        i++;
                    }
                    parent.redraw();

                    this.itemsLoaded.then(() => {
                        if (Formio.cache[this.component.data.url + this.loadedFromUrl + this.component.id]) {
                            // safety for infinity loops
                            let i = 0;
                            let parent = this.parent;
                            while (parent.type !== 'editgrid' && i < 50) {
                                parent = parent.parent;
                                i++;
                            }
                            parent.redraw();
                        }
                        if (this.defaultDownloadedResources.length > 0) {
                            Formio.cache[this.component.data.url + this.loadedFromUrl + this.component.id] = this.defaultDownloadedResources;
                            result = Formio.cache[this.component.data.url + this.loadedFromUrl + this.component.id].find(x => x.value === value);

                            let i = 0;
                            let parent = this.parent;
                            while (parent.type !== 'editgrid' && i < 50) {
                                parent = parent.parent;
                                i++;
                            }

                            parent.redraw();
                            return result?.label;
                        }
                    });
                }
            }
            result = this.defaultDownloadedResources.find(x => x.value === value);

            if (result) {
                return result?.label;
            }
        }
        else {
            return this.getValueAsString(value, options);
        }
    }

    
    get shouldLoad() {
        if (this.loadingError) {
            return false;
        }
        // Live forms should always load.
        return true;
    }    
}



// Register the component to the Formio.Components registry.
Components.addComponent("select", SelectExtend);