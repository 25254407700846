export const form = function (ctx) {
  var str = '';
  if (ctx.options.vpat) {
    str += `<span tabindex="-1" class="sr-only" id="invisible-${ctx.instance.id}-${ctx.component.key}"></span>`
  }
  if (!ctx.self.imageUpload) {
    if (ctx.options.vpat) {
      str += `<div>${(!ctx.component.filePattern || ctx.component.filePattern === '*') ? 'Any file types are allowed' : ctx.t('Allowed file types: ') + ctx.component.filePattern}</div>`
    }
    if (!ctx.disabled)
      {
        str += `<ul class="list-group list-group-striped">
        <li class="list-group-item list-group-header hidden-xs hidden-sm">
          <div class="row">`
        
        str += `<div class="col-md-${ctx.self.hasTypes ? '6' : '10'}"><strong>${ctx.t('File Name')}</strong></div>`
        if (ctx.self.hasTypes) {
          str += `<div class="col-md-4"><strong>${ctx.t('Document Type')}</strong></div>`
        }
        str += `<div class="col-md-2"></div>`
        str += `</div>
        </li>`
        ctx.files.forEach(function (file) {
          str += `<li class="list-group-item">
            <div class="row display-table-100">`
          str += `<div class="col-md-${ctx.self.hasTypes ? '6' : '10'} table-cell-align-vertical-midle">`
          if (ctx.component.uploadOnly) {
            str += `${file.originalName || file.name}`
          } else {
            str += `<a href="${file.url || '#'}" target="_blank" ref="fileLink" title="${file.originalName || file.name}">
                    <span class="sr-only">${ctx.t('Press to open ')}</span>${file.originalName || file.name}
                  </a>`
          }
          str += `</div>`
          if (ctx.self.hasTypes) {
            str += `<div class="col-md-4 table-cell-align-vertical-midle">
                  <select class="file-type" ref="fileType">`
            ctx.component.fileTypes.map(function (type) {
              str += `<option class="test" value="${type.value}" ${(type.label === file.fileType) ? 'selected="selected"' : ''}>${ctx.t(type.label)}</option>`
            });
            str += `</select>
              </div>`
          }
          str += `<div class="col-md-2 table-cell-align-vertical-midle">`
          if (ctx.data.hasOwnProperty('showFilePreviewInTabIcon') && ctx.data.showFilePreviewInTabIcon)
            str += `<i tabindex="0" class="ms-1 c-icon-grey urp-icon urp-icon-external-link" ref="filePreviewInTabLink"></i>`
          if (ctx.data.hasOwnProperty('showFileDownloadIcon') && ctx.data.showFileDownloadIcon)
            str += `<i tabindex="1" class="ms-1 c-icon-grey urp-icon urp-icon-download" ref="fileDownloadLink"></i>`
          str += `<i tabindex="2" class="ms-1 c-icon-grey urp-icon urp-icon-delete" ref="removeLink"></i>`
          str += `</div>`
          str += `</div>
          </li>`
        })
        str += `</ul>`
      } 
      ////  view mode start
      else {
        // Desktop
        str += `<ul class="list-group file-view d-none d-lg-block">`
        ctx.files.forEach(function (file) {
          str += `<li class="list-group-item">
            <div class="row">
              <div class="col-md-10">
                <div>`
                
                if (ctx.component.uploadOnly) {
                  str += `${file.originalName || file.name}`
                } else {
                  str += `<a href="${file.url || '#'}" target="_blank" ref="fileLink" title="${file.originalName || file.name}" class="primary-color">
                          <span class="sr-only">${ctx.t('Press to open ')}</span>${file.originalName || file.name}
                        </a>`
                }
          str += `</div>
                  <div class="fileTypeText">${file.fileType}</div> `  
                
          str += `</div>`
            str += `<div class="col-md-2 icon-column">`
              str += `<div>`
              if (ctx.data.hasOwnProperty('showFilePreviewInTabIcon') && ctx.data.showFilePreviewInTabIcon)
                str +=`<span tabindex="0" class="ms-1 c-icon-grey urp-icon urp-icon-external-link" ref="filePreviewInTabLink" title="Preview" alt="preview"></span>`
              if (ctx.data.hasOwnProperty('showFileDownloadIcon') && ctx.data.showFileDownloadIcon)
                str +=`<span tabindex="1" class="ms-1 c-icon-grey urp-icon urp-icon-download" ref="fileDownloadLink" title="Download" alt="download"></span>`
              str += `</div>
                    </div>
                </div>
          </li>`
        })
        str += `</ul>`
      //Mobile
      str += `<div class="container">
                <div class="d-flex flex-column gap-16 d-lg-none cp-mt-20">`
      ctx.files.forEach(function (file) {
        str += `<div class="cp-card cp-pt-10 cp-pb-10 cp-pe-16 cp-ps-16">
                  <div class="d-flex justify-content-between cp-text-xsmall cp-text-black">
                    <span class="cp-font-weight-600 cp-w-50">${ctx.t('File Name')}</span>`
                    if (ctx.component.uploadOnly) {
                      str += `<span class="cp-w-50">${file.originalName || file.name}</span>`
                    } else {
                      str += `<a href="${file.url || '#'}" target="_blank" ref="fileLink" title="${file.originalName || file.name}" class="primary-color cp-w-50">
                              <span class="sr-only">${ctx.t('Press to open ')}</span>${file.originalName || file.name}
                            </a>`
                    }
        str += `</div>`
        str += `<div class="d-flex justify-content-between cp-text-xsmall cp-text-black">
          <span class="cp-font-weight-600 cp-w-50">${ctx.t('Document Type')}</span>
          <span class="cp-w-50 fileTypeText">${file.fileType}</span>`
        str += `</div>`
        str += `<div class="d-flex justify-content-between cp-text-xsmall cp-text-black">
          <span class="cp-font-weight-600 cp-w-50">${ctx.t('Action')}</span><div class="cp-w-50">`
        if (ctx.data.hasOwnProperty('showFilePreviewInTabIcon') && ctx.data.showFilePreviewInTabIcon)
          str +=`<span tabindex="0" class="ms-1 c-icon-grey urp-icon urp-icon-external-link" ref="filePreviewInTabLink" title="Preview" alt="preview"></span>`
        if (ctx.data.hasOwnProperty('showFileDownloadIcon') && ctx.data.showFileDownloadIcon)
          str +=`<span tabindex="1" class="ms-1 c-icon-grey urp-icon urp-icon-download" ref="fileDownloadLink" title="Download" alt="download"></span>`
        str += `</div></div></div>`                          
      })
      str += `</div></div>`  
      }
  } else {
    str += `<div>`
    ctx.files.forEach(function (file) {
      str += `<div>
        <span>
          <img ref="fileImage" src="" alt="${file.originalName || file.name}" style="width:${ctx.component.imageSize}px">`
      if (!ctx.disabled) {
        str += `<i tabindex="0" class="${ctx.iconClass('remove')}" ref="removeLink"></i>`
      }
      str += `</span>
      </div>`
    })
    str += `</div>`
  }
  //// view mode end
  if (!ctx.disabled && (ctx.component.multiple || !ctx.files.length)) {
    if (ctx.self.useWebViewCamera) {
      str += `<div class="fileSelector">
      <button class="btn btn-primary" ref="galleryButton"><i class="fa fa-book"></i> ${ctx.t('Gallery')}</button>
      <button class="btn btn-primary" ref="cameraButton"><i class="fa fa-camera"></i> ${ctx.t('Camera')}</button>
    </div>`
    } else if (!ctx.self.cameraMode) {
      str += `<div class="fileSelector" ref="fileDrop" ${ctx.fileDropHidden ? 'hidden' : ''}>
      <i class="${ctx.iconClass('cloud-upload')}"></i> ${ctx.t('Drop files to attach,')}`
      if (ctx.self.imageUpload && ctx.component.webcam) {
        str += `<a href="#" ref="toggleCameraMode"><i class="fa fa-camera"></i> ${ctx.t('use camera')}</a>`
      }
      str += `${ctx.t('or')}
        <a href="#" ref="fileBrowse" class="browse">
          ${ctx.t('browse')}
          <span class="sr-only">
            ${ctx.t('Browse to attach file for ' + ctx.component.label + '. ' +
        (ctx.component.description ? ctx.component.description + '. ' : '') +
        ((!ctx.component.filePattern || ctx.component.filePattern === '*') ? 'Any file types are allowed' : ctx.t('Allowed file types: ') + ctx.component.filePattern))}
          </span>
        </a>
      <div ref="fileProcessingLoader" class="loader-wrapper">
        <div class="loader text-center"></div>
      </div>
    </div>`
    } else {
      str += `<div class="video-container">
      <video class="video" autoplay="true" ref="videoPlayer" tabindex="-1"></video>
    </div>
    <button class="btn btn-primary" ref="takePictureButton"><i class="fa fa-camera"></i> ${ctx.t('Take Picture')}</button>
    <button class="btn btn-primary" ref="toggleCameraMode">${ctx.t('Switch to file upload')}</button>`
    }
  }
  ctx.statuses.forEach(function (status) {
    str += `<div class="file ${ctx.statuses.status === 'error' ? ' has-error' : ''}">
    <div class="row">
      <div class="fileName col-form-label col-sm-10">${status.originalName}
        <i class="${ctx.iconClass('remove')}" ref="fileStatusRemove">
          <span class="sr-only">${ctx.t('Remove button. Press to remove ' + status.originalName || status.name + '.')}</span>
          <span class="sr-only">${status.message ? status.message.replace(';', '.') : ''}</span>
        </i>
      </div>
      <div class="fileSize col-form-label col-sm-2 text-right">${ctx.fileSize(status.size)}</div>
    </div>
    <div class="row">
      <div class="col-sm-12">`
    if (status.status === 'progress') {
      str += `<div class="progress">
            <div class="progress-bar" role="progressbar" aria-valuenow="${status.progress}" aria-valuemin="0" aria-valuemax="100" style="width: ${status.progress}%">
              <span class="sr-only">${status.progress}% ${ctx.t('Complete')}</span>
            </div>
          </div>`
    } else if (status.status === 'error') {
      str += `<div class="alert alert-danger bg-${status.status}">${ctx.t(status.message)}</div>`
    } else {
      str += `<div class="bg-${status.status}">${ctx.t(status.message)}</div>`
    }
    str += `</div>
    </div>
  </div>`
  })
  if (!ctx.component.storage || ctx.support.hasWarning) {
    str += `<div class="alert alert-warning">`
    if (!ctx.component.storage) {
      str += `<p>${ctx.t('No storage has been set for this field. File uploads are disabled until storage is set up.')}</p>`
    }
    if (!ctx.support.filereader) {
      str += `<p>${ctx.t('File API & FileReader API not supported.')}</p>`
    }
    if (!ctx.support.formdata) {
      str += `<p>${ctx.t("XHR2's FormData is not supported.")}</p>`
    }
    if (!ctx.support.progress) {
      str += `<p>${ctx.t("XHR2's upload progress isn't supported.")}</p>`
    }
    str += `</div>`
  }

  return str;
}