import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';
import { routesEnum } from '../../../enumerators/routesEnum';
import { IConfig } from '../../../models/config.interface';
import { FormsService } from '../../../services/forms.service/forms.service';
import { HttpService } from '../../../services/http-service/http.service';
import { ToastrServiceExt } from '../../../services/toastr.service/toastr.service';
import { RegisterDTO, RegisterServiceDTO } from '../../../models/registerdto.model';
import { RegisterServiceService } from '../../../services/register-service.service/register-service.service';
import { RegisterService } from "../../../services/register.service/register.service";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Guid } from 'guid-typescript';
import { Location } from '@angular/common';

@Component({
    selector: 'app-register-service-details',
    templateUrl: './register-service-details.component.html'
})

export class RegisterServicesDetailsComponent implements OnInit {
    loading = false;
    routesEnum = routesEnum;
    listUrl = routesEnum.registerServicesList.url;

    _registerServiceId: Guid;
    _register: RegisterDTO;
    registerServiceDTO: RegisterServiceDTO;
    safeURL: SafeResourceUrl;

    inboxImage = '/assets/images/inbox.jpg';

    constructor(protected toastr: ToastrServiceExt,
        protected router: Router,
        protected activatedRoute: ActivatedRoute,
        protected readonly keycloak: KeycloakService,
        protected apiService: HttpService,
        protected formsService: FormsService,
        protected registerServiceService: RegisterServiceService,
        protected registerService: RegisterService,
        protected toastrService: ToastrServiceExt,
        protected sanitizer: DomSanitizer,
        private location: Location,
        @Inject(APP_CONFIG) private readonly environment: IConfig) {
         }

    ngOnInit(): void {
        this.inboxImage = '/assets/images/' + this.environment.env.projectCode + '-inbox.jpg';
        
        this.activatedRoute.queryParamMap.subscribe((params) => {
            if(params.get('RegisterServiceId'))
            {
                this._registerServiceId = Guid.parse(params.get('RegisterServiceId'));
                this.registerChanged(this._register);
            }
        })
    }

    registerChanged = (register): void => {
        this._register = register;
        if(this._registerServiceId){
            this
                .apiService
                .get(this.environment.api.service + '/' + this._registerServiceId.toString())
                .subscribe(response => {
                    this.registerServiceDTO = response;
                    this.safeURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.registerServiceDTO.videoGuidelines);
                }, (err) => {
                    this.toastr.serverError(err);
                });
        }
    }

    startProcess(): void {
        this.registerServiceService.startProcess(this._registerServiceId, this.registerServiceDTO.ProcessDefinitionKey, this.registerServiceDTO.RegisterId,
            this.registerServiceDTO.StartingFormCode, this.registerServiceDTO.StartingFormId,  this.activatedRoute);
    }
    goBack() {
        this.location.back();
    }
}
