import { Component, ElementRef, HostListener, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { routesEnum } from '../../../../enumerators/routesEnum';
import { formEnum } from '../../../../enumerators/Forms.enum';
import { HttpService } from 'projects/urp/src/app/services/http-service/http.service';
import { ToastrServiceExt } from 'projects/urp/src/app/services/toastr.service/toastr.service';
import { IConfig } from 'projects/urp/src/app/models/config.interface';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';
import { MenuService } from 'projects/urp/src/app/services/menu.service/menu.service';

@Component({
  selector: 'app-header-user',
  templateUrl: './header-user.component.html'
})
export class HeaderUserComponent implements OnInit {
  public userProfile: any;
  public userInfo: any;
  public institution = '';
  private readonly url = `${routesEnum.profile.url}`;
  public token: string;
  userMenuShown = false;

  constructor(public keycloak: KeycloakService,
    private router: Router,
    private httpService: HttpService,
    private toastr: ToastrServiceExt,
    private eRef: ElementRef,
    private menuService: MenuService,
    @Inject(APP_CONFIG) private readonly environment: IConfig) {
  }

  public ngOnInit(): void {
    this.menuService.refreshMenuEvent.subscribe(() => {
      this.refreshUser();
    })
    
  }

  refreshUser() : void {
    void this.keycloak.isLoggedIn().then((loggedIn) => {
      if (loggedIn) {
        // TODO find out why user profile doesnt update after token refesh
        void this.keycloak.getKeycloakInstance().loadUserInfo().then(user => {         
          this.userInfo = user;          
          if(!user['userId']) {
            this.menuService.showMenuEvent.next(false);
            void this.router.navigate([this.url]);
          }
        });
        void this.keycloak.loadUserProfile().then(profile => {
          this.userProfile = profile;          
        })
      }
    });
  }

  public Logout(): void {
    void this.keycloak.logout().then(() => {
      this.keycloak.clearToken();
      void this.router.navigate([routesEnum.home.url]);
    });
  }

  public async Profile(): Promise<void> {
    if (this.userProfile['attributes']?.userId) {
      await this.router.navigate([this.url], { queryParams: { Id: this.userProfile['attributes']?.userId[0] } });
    }
    else {
      await this.router.navigate([this.url]);
    }
  }

  showUserMenu(): void {
    this.userMenuShown = !this.userMenuShown;
    this.checkChevron();
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.userMenuShown = false;
      this.checkChevron();
    }
  }
  checkChevron() {
    if (this.userMenuShown) {
      document.getElementById('chevron').classList.add("urp-icon-user-chevron-up");
      document.getElementById('chevron').classList.remove("urp-icon-user-chevron-down");
    } else {
      document.getElementById('chevron').classList.add("urp-icon-user-chevron-down");
      document.getElementById('chevron').classList.remove("urp-icon-user-chevron-up");
    }
  }
}
