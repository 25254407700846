<!-- Desktop -->
<div class="d-none d-lg-block">
    <div *ngIf="showMenu" class="sidebar-menu1 d-flex flex-column h-100">
        <ng-container *ngFor="let menuItem of menuStructure">
            <li *ngIf="!hasChild(menuItem); else withChildren" class="nav-item w-100" [class.active]="isActiveUrl(menuItem)">
                    <a translate class="nav-link my-1 d-flex flex-row" routerLink="{{menuItem.path}}" (click)="closeNav(menuItem)" [queryParams]="menuItem.queryParams" title="{{menuItem.label | translate}}">
                        <i class="pt-1" [ngClass]="menuItem.activeMenu ? menuItem.iconClass + ' c-icon-primary' : menuItem.iconClass + ' c-icon-grey'"></i>
                        <span class="ms-3 collapseDisappear c-retractable" translate>{{menuItem.label}}</span></a>
                
            </li>
            <ng-template #withChildren>
                <li *ngIf="!menuItem.parentMenuItemId" class="parent nav-item w-100" [class.active]="isActiveUrl(menuItem)">
                
                        <a translate class="nav-link my-1 d-flex flex-row" (click)="toggleMenu(menuItem, false)" title="{{menuItem.label | translate}}">
                            <i class="pt-1" [ngClass]="menuItem.activeMenu ? menuItem.iconClass + ' c-icon-primary' : menuItem.iconClass + ' c-icon-grey'"></i>
                            <span class="ms-3 collapseDisappear c-retractable" translate>{{menuItem.label}}</span>
                            <i class="pt-1 ms-1" class="c-icon c-icon-grey c-icon-20 urp-icon-chevron-right2"></i>
                        </a>
                            
                        <!-- <div class="sidebar-ul-wrapper">
                            <ul class="sidebar-ul">
                                <li *ngFor="let childItem of getItemChildren(menuItem)" class="nav-item w-100" [class.active]="isActiveUrl(childItem.path)">
                                    <div class="ps-1" >
                                        <a translate class="nav-link my-1 d-flex flex-row" routerLink="{{childItem.path}}" title="{{childItem.label | translate}}">
                                            <i class="pt-1"></i>
                                            <span class="ms-3 collapseDisappear" translate>{{childItem.label}}</span></a>
                                    </div>
                                </li>
                            </ul>
                        </div> -->
                </li> 
            </ng-template>
        </ng-container>
    
    </div>
</div>

<!-- Mobile -->
<div class="d-flex flex-column gap-16 d-lg-none cp-ms-20" id="sideBarMain">
    <div *ngIf="showMenu && isObjectEmpty(sideBarSecondData)" class="sidebar-menu1 d-flex flex-column h-100">
        <ng-container *ngFor="let menuItem of menuStructure">
            <li *ngIf="!hasChild(menuItem); else withChildren" class="nav-item w-100" [class.active]="isActiveUrl(menuItem)">
                    <a translate class="nav-link my-1 d-flex flex-row" routerLink="{{menuItem.path}}" (click)="closeNav(menuItem)" [queryParams]="menuItem.queryParams" title="{{menuItem.label | translate}}">
                        <i class="pt-1" [ngClass]="menuItem.activeMenu ? menuItem.iconClass + ' c-icon-primary' : menuItem.iconClass + ' c-icon-grey'"></i>
                        <span class="ms-3 collapseDisappear c-retractable" translate>{{menuItem.label}}</span></a>
            </li>
            <ng-template #withChildren>
                <li *ngIf="!menuItem.parentMenuItemId" class="parent nav-item w-100" [class.active]="isActiveUrl(menuItem)">
                        <a translate class="nav-link my-1 d-flex flex-row" (click)="toggleMenu(menuItem, true)" title="{{menuItem.label | translate}}">
                            <i class="pt-1" [ngClass]="menuItem.activeMenu ? menuItem.iconClass + ' c-icon-primary' : menuItem.iconClass + ' c-icon-grey'"></i>
                            <span class="ms-3 collapseDisappear c-retractable" translate>{{menuItem.label}}</span>
                            <i class="pt-1 ms-1" class="c-icon c-icon-grey c-icon-20 urp-icon-chevron-right2"></i>
                        </a>
                </li> 
            </ng-template>
        </ng-container>
    </div>
    <div *ngIf="showMenu && !isObjectEmpty(sideBarSecondData)" class="sidebar-menu1 d-flex flex-column h-100">
        <div class="display-table-100">
            <div class="table-cell-align-vertical-midle">
                <span role="button" (click)="closeSecondMenu()" class="c-icon-grey urp-icon urp-icon-chevron-left" title="{{'pages.common.list.closeFilterButton' | translate}}" alt="close"></span>
            </div>
            <div class="table-cell-align-vertical-midle">
                <h6 class="cp-mb-0">{{main.label | translate}}</h6>
            </div>
        </div>
        
        <ul class="cp-sidebar__submenu">
            <li *ngFor="let childItem of children" class="cp-sidebar__item">
                <a translate class="nav-link my-1 ms-2 d-flex flex-row" (click)="closeNav(null)" routerLink="{{childItem.path}}" title="{{childItem.label | translate}}">
                    <span class="collapseDisappear" translate>{{childItem.label}}</span>
                </a>
            </li>
        </ul>
    </div>
</div>