import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IConfig } from '../../models/config.interface';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  constructor(private http: HttpClient,
    @Inject(APP_CONFIG) private readonly environment: IConfig) { 
  }

  getPermissionsForResourceCreation(): Observable<unknown> {
    return this.http.get(`${this.environment.api.security}/GetPermissionsForResourceCreation`);
  }

}
