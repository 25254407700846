export const form = function (ctx) {
  var str = '';
  str += `<ul class="editgrid-listgroup list-group
    ${ctx.component.striped ? 'table-striped' : ''}
    ${ctx.component.bordered ? 'table-bordered' : ''}
    ${ctx.component.hover ? 'table-hover' : ''}
    ${ctx.component.condensed ? 'table-sm' : ''}
    ">
  ${ctx.header ?
      `<li class="list-group-item list-group-header green">
    ${ctx.header}
  </li>` : ''}`;

  if (ctx.rows) {
    ctx.rows.forEach(function (row, rowIndex) {
      str += `<li class="list-group-item green" ref="${ctx.ref.row}">
    ${row}
    ${(ctx.openRows[rowIndex]) ? `<div class="editgrid-actions">
    ${!ctx.readOnly && !ctx.disabled ?
            `<button class="btn btn-primary" ref="${ctx.ref.saveRow}">${ctx.t(ctx.component.saveRow || 'Save', { _userInput: true })}</button> 
      <button class="btn btn-outline-primary" ref="${ctx.ref.cancelRow}">${ctx.t(ctx.component.removeRow || 'Cancel', { _userInput: true })}</button>` : 
      `<button class="btn btn-outline-primary" ref="${ctx.ref.cancelRow}">${ctx.t('Close', { _userInput: true })}</button>`}
      </div>` : ''}
    ${ctx.errors[rowIndex] ?
          `<div class="has-error">
      <div class="editgrid-row-error">
        ${ctx.errors[rowIndex]}
      </div>
    </div>
  </li>` : ''}
  ${(ctx.footer) ?
          `<li class="list-group-item list-group-footer">
    ${ctx.footer}
  </li>` : ''}
    `})
  }
  str += `</ul>`;
  if (!ctx.readOnly && ctx.hasAddButton) {
    str += `<button class="btn btn-primary" ref="${ctx.ref.addRow}">
    <i class="${ctx.iconClass('plus')}"></i> ${ctx.t(ctx.component.addAnother || 'Add Another', { _userInput: true })}
    </button>`;
  };

  return str;
}