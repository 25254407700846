<app-session-modal #modal [showExitButton]="false" [modalClass]="'modal-md'">
    <div header>
      <img class="largeIcon" src="./assets/images/session-timeout.png" alt="session-timeout"/>
      <ng-container [ngSwitch]="true">
        <label *ngSwitchCase="secondsRemaining() > 0">{{'pages.common.sessionEndingSoonHeader' | translate}}</label>
        <label *ngSwitchDefault>{{'pages.common.sessionExpiredHeader' | translate}}</label>
      </ng-container>
    </div>
    <div body>
      <ng-container [ngSwitch]="true">
        <ng-container *ngSwitchCase="secondsRemaining() > 0">
          <label >{{'pages.common.sessionEndingSoonMessage' | translate}}</label>
          <div>{{displaySecondsRemaining()}}</div>
        </ng-container>
        <label *ngSwitchDefault>{{'pages.common.sessionExpiredMessage' | translate}}</label>
      </ng-container>
    </div>
    <div footer>
      <ng-container [ngSwitch]="true">
        <ng-container *ngSwitchCase="secondsRemaining() > 0 || failedToRefreshToken()">
          <button type="button" (click)="sessionContinueAction()" class="btn green-button">
            <label>{{'pages.common.buttonContinue' | translate}}</label>
          </button>
          <button type="button" (click)="sessionLogoutAction()" class="btn white-button">
            <label>{{'pages.common.buttonQuit' | translate}}</label>
          </button>
        </ng-container>
        <button *ngSwitchDefault type="button" (click)="sessionCloseAction()" class="btn white-button">
          <label>{{'pages.common.buttonQuit' | translate}}</label>
        </button>
      </ng-container>
    </div>
  </app-session-modal>