import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuService } from 'projects/urp/src/app/services/menu.service/menu.service';
import { BaseMenuComponent } from '../../../shared/base-menu/base-menu';

@Component({
  selector: 'app-sidebar-second',
  templateUrl: './sidebar-second.component.html',
  
})

export class SidebarSecondComponent extends BaseMenuComponent {
  constructor(protected menuService: MenuService) {
    super(menuService);
  }
  data = null;
  title = '';
  children = [{}];
  ngOnInit() {
    this.menuService.selectedSideBarSecondData$.subscribe((value) => {
      this.data = value;
      if (!this.isObjectEmpty(this.data)) {
        const main = value.find(m => !m.parentMenuItemId);
        this.children = value.filter(m => m.parentMenuItemId);
        this.title = main.label;
      }
    });
  }
  close() {
    this.menuService.setSideBarSecondData(null);
    this.maxSideMenu();
  }
  clickMenu(childItem) {
    this.children.forEach(m => {
      m['activeMenu'] = false;
    });
    childItem.activeMenu = true;
  }
}
