import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { routesEnum } from '../../../enumerators/routesEnum';
import { KeycloakService } from 'keycloak-angular';
import { APP_CONFIG } from '../../../utility/tokens';
import { IConfig } from '../../../models/config.interface';
import { HttpService } from '../../../services/http-service/http.service';

@Component({
  selector: 'app-my-office',
  templateUrl: './my-office.component.html',
  styleUrls: ['./my-office.component.scss']
})
export class MyOfficeComponent implements OnInit {

  routesEnum = routesEnum;
  registers: any;
  userInfo: any;
  loading = true;

  constructor(
    public keycloak: KeycloakService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected httpService: HttpService,
    @Inject(APP_CONFIG) private readonly environment: IConfig) {
  }
  ngOnInit(): void {
    this.getUserData();
    this.httpService.get(`${this.environment.api.registers}/search`).subscribe(res => {
      this.registers = res;
      this.loading = false;
    });
  }

  selectPaymetRegister(item): void {
    alert('Method not implemented');
  }
  getUserData()
  {
    void this.keycloak.getKeycloakInstance().loadUserInfo().then(user => {
      this.userInfo = user;
      this.loading = false;
    });
  }
}