import { Component, Inject } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { routesEnum } from '../../../../enumerators/routesEnum';
import { formEnum } from '../../../../enumerators/Forms.enum'
import { DefaultParams } from '../../../../models/filter.model'
import { BaseListComponent } from '../../../shared/base-list/base-list.component';
import { HttpService } from '../../../../services/http-service/http.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TableColumn } from 'projects/urp/src/app/models/table-column.model';
import { ToastrServiceExt } from 'projects/urp/src/app/services/toastr.service/toastr.service';
import { IConfig } from 'projects/urp/src/app/models/config.interface';
import { APP_CONFIG } from 'projects/urp/src/app/utility/tokens';
import { HelperService } from 'projects/urp/src/app/services/helper.service/helper.service';
import { SystemResourceType, SubSystem } from 'projects/urp/src/app/enumerators/classifier.enum';

@Component({
  selector: 'app-roles-list',
  templateUrl: './roles-list.component.html'
})
export class RolesListComponent extends BaseListComponent {
  searchData = new DefaultParams('Name asc');
  defaultSearchData = new DefaultParams('Name asc');

  listUrl = routesEnum.roleList.url;
  formEnum = formEnum.roleForm;

  disableAddNew: boolean;
  tableColumns = [
    new TableColumn('Name', 'pages.role.list.data.name'),
    //new TableColumn('Register', 'pages.role.list.data.register', 'guid', "REGISTERS"),
    new TableColumn(null, null, 'action')
  ];

  constructor(protected toastrService: ToastrServiceExt, 
              protected modal: BsModalService,
              protected router: Router,
              protected readonly keycloak: KeycloakService,
              protected httpService: HttpService,
              protected location: Location,
              protected route: ActivatedRoute,
              protected helperService: HelperService,
              @Inject(APP_CONFIG) private readonly environment: IConfig) { 
    super(toastrService, modal, router, route, keycloak, location, httpService);
    this.apiUrl = this.environment.api.umRoles;
    this.disableAddNew = !helperService.hasPermissionForResourceCreation(SubSystem.ADM, SystemResourceType.Role);
  }

  protected override loadClassifiers(): void {
    this.httpService.get(`${this.environment.api.registers}/selectSearch`).subscribe(res => {
        this.classifierData.REGISTERS = res;
        this.classifiersLoading = false;

        this.loadList();
      }, (err) => {
        this.toastrService.serverError(err);
      });
  }

}
