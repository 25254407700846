<div class="modal-header bg-white">
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="decline()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
</div>
<div class="modal-body form-holder bg-white">
    <div class="form-group bg-white">
        <div class="form-element bg-white">
            <label translate for="isChecked" class="label--checkmark text-dark">
                {{message}}
            </label>
        </div>
    </div>
</div>
<div class="modal-footer bg-white">
    <button type="button" class="btn btn-outline-primary btn-block w-100" (click)="decline()">{{'pages.common.no' | translate}}</button>
    <button type="button" class="btn btn-primary btn-block w-100" (click)="confirm()">{{'pages.common.yes' | translate}}</button>   
</div>