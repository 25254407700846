import { Component, Directive, OnInit, Input, Output, EventEmitter, QueryList, ViewChildren, ElementRef, Renderer2} from '@angular/core';
import { DataTableComponent } from '../../../shared/data-table/data-table.component';


@Component({
  selector: 'app-push-notification-data-table',
  templateUrl: './push-notification-data-table.component.html',
  styleUrls: ['./push-notification-data-table.component.scss']
})
export class PushNotificationDataTableComponent extends DataTableComponent {
  allSelected = false;

  onAllSelected() {
    this.allSelected = !this.allSelected;
    this.tableData.forEach(element => {
      element.IsSelected = this.allSelected;
    });
  }

  onDeleteMarked() {
    const selectedIds = this.getSelected();
    if(selectedIds.length > 0)
      this.deleteMarked.emit(selectedIds);
  }
  onEditMarked() {
    const selectedId = this.getSelected();
    if(selectedId.length > 0)
      this.editMarked.emit(selectedId);
  }
  getSelected() {
    let selectedIds = [];
    this.tableData.forEach(element => {
      if (element.IsSelected) {
        selectedIds.push(element.Id);
      }
    });
    return selectedIds;
  }
  onSelect(item) {
    item.IsSelected = true;
  }
}