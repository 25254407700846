import { Injectable, Inject } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { HttpService } from '../http-service/http.service';
import { IConfig } from '../../models/config.interface';
import { APP_CONFIG } from '../../utility/tokens';
import { ToastrServiceExt } from '../toastr.service/toastr.service';
import { routesEnum } from '../../enumerators/routesEnum';
import { ActivatedRoute, Router } from '@angular/router';
import { Guid } from "guid-typescript";

@Injectable({
    providedIn: 'root'
})

export class RegisterServiceService {
    
  constructor(private apiService: HttpService,
    private toastr: ToastrServiceExt,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(APP_CONFIG) private readonly environment: IConfig) { }

    registerService = new BehaviorSubject<any>(null);

    callInProgress: boolean;

    startProcess(serviceId: Guid, processDefinitionKey: string, registerId: Guid, formCode: string, formId: string,
         activatedRoute: ActivatedRoute): void {
        const data: any = {};
        data.RegisterId = registerId;
        data.FormCode = formCode;
        data.RegisterServiceId = serviceId.toString();
        data.FormId = formId.toString();      
        
        if(!this.callInProgress){
            this.callInProgress = true;

            this.apiService
                .post(
                    `${this.environment.api.appProcess}/start/${processDefinitionKey}`,
                    data
                )
                .subscribe(
                    (res) => {
                        void this.router.navigate([routesEnum.form.url], {
                            relativeTo: activatedRoute,
                            queryParams: {
                                FormCode: res.FormCode,
                                FormId: res.FormId,
                                Id: res.Entity.Id,
                                RegisterId: registerId                                
                            },
                        });

                        this.callInProgress = false;
                    },
                    (err) => {
                        this.toastr.serverError(err);

                        this.callInProgress = false;
                    }
                );
        }
    }
}
