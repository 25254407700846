import { Component, Directive, OnInit, Input, Output, EventEmitter, QueryList, ViewChildren, ElementRef, Renderer2 } from '@angular/core';
import { TableColumn } from '../../../models/table-column.model';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

interface list {
}

export type SortColumn = keyof list | '';
export type SortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: SortDirection } = { 'asc': 'desc', 'desc': '', '': 'asc' };

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

export interface SortEvent {
  column: SortColumn;
  direction: SortDirection;
}


@Directive({
  selector: 'th[sortable]',
  host: {
    '(click)': 'rotate()'
  }
})

export class NgbdSortableHeader {

  @Input() sortable: SortColumn = '';
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();

  constructor(private el: ElementRef, private renderer: Renderer2, private route: ActivatedRoute) { }

  ngAfterViewInit() {
    const sortByParam = this.route.snapshot.queryParams.SortBy;

    let span = this.el.nativeElement.querySelector('span');
    this.renderer.addClass(span, "icon-sort");

    const column = sortByParam.split(' ')[0];

    if(this.el.nativeElement.attributes['id'].value === column) {
    const direction = sortByParam.split(' ')[1];

    this.direction = direction;

    this.sortIconChange();
    }
  }

  rotate() {
    this.direction = rotate[this.direction];
    this.removeSort(this.el.nativeElement.parentElement.children);
    this.sortIconChange();
  }

  sortIconChange() {
    let span = this.el.nativeElement.querySelector('span');

    switch (this.direction) {
      case "asc":
        this.renderer.removeClass(span, span.className);
        this.renderer.addClass(span, "icon-sort-down");
        break;
      case "desc":
        this.renderer.removeClass(span, span.className);
        this.renderer.addClass(span, "icon-sort-up");
        break;
      default:
        this.renderer.removeClass(span, span.className);
        this.renderer.addClass(span, "icon-sort");
    }
    this.sort.emit({ column: this.sortable, direction: this.direction });
  }

  removeSort(elements: any) {
    const render = this.renderer;
    for (let el of elements) {
      let span = el.querySelector('span');
      if (span && span.className !== "icon-sort") {
        render.removeClass(span, span.className);
        render.addClass(span, "icon-sort");
      }
    }
  }
}


@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit {
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  @Input() tableData: any; // table list
  @Input() tableColumns: TableColumn[]; // table column
  @Input() listActions: string[]; // available actions for row
  @Input() classifiers: any;
  tableColSpan = 0;

  @Output() sortChange: EventEmitter<any>;
  @Output() edit: EventEmitter<any>;
  @Output() editMarked: EventEmitter<any>;
  @Output() view: EventEmitter<any>;
  @Output() delete: EventEmitter<any>;
  @Output() deleteMarked: EventEmitter<any>;
  @Output() download: EventEmitter<any>;
  @Output() toList: EventEmitter<any>;
  @Output() claim: EventEmitter<any>;


  constructor(private translate: TranslateService) {
    this.sortChange = new EventEmitter<any>();
    this.edit = new EventEmitter<any>();
    this.editMarked = new EventEmitter<any>();
    this.view = new EventEmitter<any>();
    this.delete = new EventEmitter<any>();
    this.deleteMarked = new EventEmitter<any>();
    this.download = new EventEmitter<any>();
    this.toList = new EventEmitter<any>();
    this.claim = new EventEmitter<any>();
  }

  ngOnInit(): void {
    this.tableColSpan = this.tableColumns.length;
    this.tableData = this.tableData ?? [];
  }

  getTranslation(column: TableColumn, item): string {
    const classifierKey = item[column.id];
    return classifierKey ? `${column.classifierTranslationKey}.${classifierKey}` : '';
  }
  setClass(column: TableColumn, item) {
    if (column.id && (column.id == 'TrackingInfo.ApplicationStatusId' || column.id == 'ApplicationStatusId')) {
      let statusClass =  'tag tag--secondary tag--bubble ';
      statusClass = statusClass + item[column.id];
      return statusClass;
    } else if (column.id && (column.id == 'RegistrationObjectInfo.RegistrationObjectStatusId' || column.id == 'StatusNaturalKey')) {
      let statusClass = 'status-secondary ';
      statusClass = statusClass + item[column.id];
      return statusClass;
    } else if (column.cssClassColumn && (column.cssClassColumn = 'ApplicationStatusId')) {
      let statusClass =  'tag tag--secondary tag--bubble ';
      statusClass = statusClass + item[column.cssClassColumn];
      return statusClass;
    } else {
      return '';
    }
  }

  getLanguageCode(column, item): string {
    const code: string = item[column.id];

    return code === 'EN' ? 'US' : code;
  }

  getGuidTranslation(column: TableColumn, item): string {
    let val: string = item.value;
    const notFoudError = this.translate.instant(column.label) + ' ' + this.translate.instant('exception.Generic.NotFound');

    if (!this.classifiers) {
      return notFoudError;
    }

    if (this.classifiers && this.classifiers[column.classifierTranslationKey]) {
      const classifierObject = this.classifiers[column.classifierTranslationKey].find(x => x.value == item[column.id]);

      if (classifierObject) {
        val = classifierObject.label;
      }
      else {
        val = notFoudError;
      }
    }
    return val;
  }

  onSort({ column, direction }: SortEvent) {
    if (!this.headers) return;
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    // sorting
    if (direction === '' || column === '') {
      this.sortChange.emit('');
    } else {
      this.sortChange.emit(column + ' ' + direction);
    }
  }

  onEdit(item) {
    this.edit.emit(item);
  }

  onView(item) {
    this.view.emit(item);
  }
 
  onDelete(item) {
    this.delete.emit(item);
  }
  
  onDownload(item) {
    this.download.emit(item);
  }

  goToList(item) {
    this.toList.emit(item);
  }

  onClaim(item) {
    this.claim.emit(item);
  }

  ShowAction(action) {
    return this.listActions.includes(action);
  }

  HasRight(right, item) {
    if (item.hasOwnProperty("KCResourceAccessData") && Array.isArray(item.KCResourceAccessData))
      return item.KCResourceAccessData.includes(right);
    else
      return true;
  }

}
