<div *ngIf="userProfile"  class="header-user">
    <button (click)="showUserMenu()" [ngClass]="{active: userMenuShown}" class="btn header-user-btn mx-3 border-0 mt-1 mb-1 d-none d-lg-flex flex-row align-items-center rounded" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
        <div *ngIf="userInfo" class="text-end mx-2 py-1">
            <span>{{userInfo?.fullName}}</span>
            <br/>
            <!-- <span class="text-muted">On behalf of: </span>
            <span class="fw-bold">{{userInfo.fullName}}</span> -->
        </div>
        <div id="chevron" class="urp-icon urp-icon-user-chevron-down"></div>
    </button>
    <button mat-icon-button color="primary" (click)="showUserMenu()" [ngClass]="{active: userMenuShown}" class="d-flex d-lg-none" aria-label="User icon" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
        <mat-icon>account_circle</mat-icon>
    </button>
    <ul *ngIf="userInfo" [collapse]="!userMenuShown" [isAnimated]="true" class="dropdown-menu w-100 p-2 mt-2" aria-labelledby="dropdownMenuLink">
        <!-- <span class="text-muted">On behalf of:</span>
        <li class="px-3 py-2"><a class="d-flex flex-row" href="#"><div class="c-icon c-cb-circle-default"></div><span class="ms-3 d-block text-truncate">{{userInfo.fullName}}</span></a></li> -->
        <hr class="my-1">
        <li class="py-2 px-3"><a title="Profile" (click)='Profile()' class="profile d-flex flex-row align-items-center"><div class="urp-icon urp-icon-profile c-icon-grey"></div><span translate class="ms-3 d-block text-truncate c-text-14">sidemenu.profile</span></a></li>
        <hr class="my-1">
        <li class="py-2 px-3"><a title="Logout" (click)='Logout()' class="logout d-flex flex-row align-items-center"><div class="urp-icon urp-icon-log-out c-icon-grey"></div><span translate class="ms-3 d-block text-truncate c-text-14">sidemenu.logout</span></a></li>
    </ul>
</div>
